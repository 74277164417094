const ConfigFile =
  // there is a separate config file for extra credit processes
  {
    general: {siteURL: "" },
    activities: {levelMoodBoost: 50, levelCashBoost: 100},
    collegeItems: {collegeInterest: .035, collegeLoanTerm: 10, collegeCostPerYear: 12000},
    carItems: {carInterest: .10, carLoanTerm: 6, commuteYearlyMiles: 8320, weekendYearlyMiles: 4680, 
      gasCostPerGallon: 3.20, carLoanProcessFee: .10, carChangeLockedMonths: 3 },
    gameBoard: {nbrMonthsNegative: 3, nbrMonthsMoodNegative: 3, baseLineExpenses: 500, retirementBase: 500, 
      eventLogging: false, loggingMax: 100,  
      badgeMonths: [3, 16, 29, 42, 55, 85, 100, 130, 160, 190, 220, 250, 280, 310, 340], 
      moodPointMultiplier: 50},
    gameBoardLoans: {intRate: .10, loanTerm: 6, pctDownPayment: .20 },
    gameChoice: {carChangeMood: 6000, homeChangeMood: 4000, roommateChangeMood: 2000, jobChangeMood: 4000},
    homeItems: {kitchenCost: 300, miscSupplies: 150, homeChangeLockedMonths: 3, mortgageInterest: .04, 
        minHomeCost: 300000, PMIRate: 1.2,
        mortgageLoanTerm: 30, downPayMinPct: .05, baseRentAmt: 1500.00, baseMovingCost: 500.00, costPerBedroom: 75.00},
    insurance: {healthInsEmployeePct: .30, healthInsAmt: 370, rentersInsAmt: 30, carInsAmt: 75},
    unlockedFeatures: {savingsOverDraftFee: 20, creditCardInterest: .20, creditCardMinimumPayPct: .04, 
      periodsCreditHistory: 4, creditCardLateFee: 30},
    gameStartItems: {totalNbrGameMonths: 360, monthlyMiscCost: 380, startingMoodPoints: 16000, 
        maxMoodPoints: 50000},  
    login: {accountServiceID: "service_r04q4fv", accountPublicID: "pVtw3upqYGQ8-9iWu", 
        accountTemplate: "template_l0g2th8", passwordTemplate: "template_ve4uap8",
        contactPublicID: "Llk4rHviqfGTX9fMp", contactServiceID: "service_d9kblbo", contactTemplate: "template_6uj2w2c"  }, 
  
    };

export default ConfigFile