import {Component} from 'react';


class WaitTimeSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true,
           };
    }

    render() { 
        this.spinnerClass = "wait-spinner-container";
        switch(this.props.waitTimerPosition){
            case "wide":
                this.spinnerClass = this.spinnerClass + " spinner-wide-position";
                break;
            case "modal":
                this.spinnerClass = this.spinnerClass + " spinner-modal-position";
                break;
            default:
                this.spinnerClass = this.spinnerClass + " spinner-position";
                break;
        }
        
    return <>
            <div className={this.spinnerClass}>
                <img src="./assets/TrainOnCircleTrack.png"
                        className="money-train-spinner" alt="moneyTrain"/>
                <div className="centered decorative medium-large-text
                    spinner-text-position">MoneyTrain</div>
            </div>
    </>
    }
  
}
 
export default WaitTimeSpinner;