import React from 'react';
import {Component} from 'react';
import Header from './components/shared-components/header';
import LoginForm from './components/login-related/login-form';
import LogoutForm from './components/login-related/logout-form';
import Footer from './components/shared-components/footer';
import Homepage from './pages/homepage';
//import Administration from './pages/administration';
//import GettingStartedDetailed from './pages/gettingStartedDetailed';
//import SpecialProcesses from './pages/specialProcesses';
//import GameView from './pages/gameView';
//import ExtraCredit from './pages/extraCredit';
//import ForParents from './pages/forParents';
import AboutPage from './pages/aboutPage';
//import Activities from './pages/activities';
//import SpecialActivity from './pages/spectialActivity';
import {UsernameContext} from './objects/Context/username_context';
import {LoginContext} from './objects/Context/login_context';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends Component {
  constructor(props)
  {
    super(props);
    const storedUsername = sessionStorage.getItem('username');
    const storedUserRole = sessionStorage.getItem('userRole');
    if (storedUsername !== null)
    {
      this.state = {displayLoginMenu: false, username: storedUsername, loggedIn: true, userRole: storedUserRole};
    }
    else
    {
      this.state = {displayLoginMenu: false, 
          username: "", 
          loggedIn: false,
          loginMenuOpened: false
      };
      console.log(`Logged in? ${this.state.loggedIn}`)
    }
  }
  render() {     
  return (
    <div id="container">
     <meta name="description" content="Money Management is an important life skill.  For many
        best practices of managing money are learned through costly mistakes.  This site offers
        a different approach.  Click on this link to explore a game that will teach basic money
        management concepts.  This game is intended for ages 10 through 14." />
    <Router>
      <UsernameContext.Provider value={this.state}>
        <Header toggleLoginMenu={() => this.toggleLoginMenu()} 
            onClick={() => this.handleBodyClick()} username={this.state.username}
            userRole={this.state.userRole} userLoggedIn={this.state.loggedIn}/>
      {this.state.displayLoginMenu ?
        (!this.state.loggedIn ?
              <LoginForm onSuccessfulLogin={this.loginCallback}/>
              :
              <LogoutForm onLogout={this.logoutCallback} username={this.state.username}/>
        )
            :
            <span/>            
      }
      </UsernameContext.Provider>
      <div id="body" onClick={() => this.handleBodyClick()}>
      <Switch>
          
      
     
     
     
                                              
        <Route path="/about">
          <AboutPage />
        </Route>
          <Route path="/about-general">
             <AboutPage selected="aboutGeneral" />
          </Route>
          <Route path="/about-for-parents">
             <AboutPage selected="aboutForParents" />
          </Route>
          <Route path="/information-sources">
             <AboutPage selected="informationSources" />
          </Route>
          <Route path="/game-objectives">
            <AboutPage selected="gameObjectives" />
          </Route>
          <Route path="/contact-us">
            <AboutPage selected="contactUs" />
          </Route> 
            
        <Route path="/">
          <LoginContext.Provider value={this.state}>
            <Homepage userLoggedIn={this.state.loggedIn}
                username={this.state.username}
                openLoginMenu={() => this.openLoginMenu()} />
          </LoginContext.Provider>
        </Route>
      </Switch>
      </div>
      <Footer onClick={() => this.handleBodyClick()}/>
    </Router>
  </div>
  );
  }

  handleBodyClick()
  {
    if (this.state.loginMenuOpened == true){
        this.state.loginMenuOpened = false;
    }else{
        this.setState({displayLoginMenu: false});
    }
  }

  toggleLoginMenu()
  {
    this.setState({displayLoginMenu: !this.state.displayLoginMenu});
  }

  openLoginMenu()
  {
    this.setState({displayLoginMenu: true});
    this.state.loginMenuOpened = true;
  }

  loginCallback = (username, userRole) =>
  {
      //  this throws an error because it is being called from the render of the login_form process
      //  but changing to direct assignments does not seem to work consistently. 
      this.setState({username: username, displayLoginMenu: false, loggedIn: true, userRole: userRole});
      console.log("loginCallBack");
  }

  logoutCallback = () =>
  {
    sessionStorage.removeItem('sessionToken');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem("userRole"); 
    this.setState({username: "", displayLoginMenu: false, loggedIn: false, userRole: ""});
    console.log("logoutCallback");
  }
}

export default App;
