import {Component} from 'react';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class InformationSources extends Component {
    
       render() {
            let screenParms = setScreenSizeParameters(this.props.screenSize, "col-sm-6", "col-sm-9", "col-sm-9");
            this.className = screenParms.columnClass;
            this.textClass = screenParms.textSize + " font-weight-bold";
            this.textClass2 = screenParms.textSize;
        
       return <>
            <div className={this.className}>
             <div className="card mt-3 rounded-corners-lg height-max">
                <h3 className="mt-2 p-0 text-center">About Page</h3>
                    <h5 className="text-center">Information Sources</h5>
                    <div className="mt-2 mb-4 mr-4 ml-4 overflow-scroll">
                    <h8 className={this.textClass}>About the Authors</h8>
                        <p className={this.textClass2}>This game was assembled by leveraging the talents of a team.
                           The primary author of the game has an MBA in Finance/Accounting as 
                           well as years working for a large discount-broker, and  
                           time spent in elementary school classroom education.   
                           Other members on the team include individuals who have financial certifications, 
                           and educational expertise.  All members of the team believe that money management is
                           an essential skill in order for individuals to be successful.
                        </p>
                    <h8 className={this.textClass}>Job Information</h8>
                        <p className={this.textClass2}>The list of jobs which are most common in the United States, job 
                           descriptions, likes and dislikes have been pulled
                           from a variety of sites including &nbsp;
                            <a href="https://www.indeed.jobs/" target="_blank"
                                className="underline-this-text">indeed.com
                                </a>
                            &nbsp;
                            and <a href="https://www.glassdoor.com/index.html" target="_blank"
                                className="underline-this-text">glassdoor</a>. 
                        </p>
                    <h8 className={this.textClass}>Salary Information</h8>
                        <p className={this.textClass2}>Salary ranges determined using &nbsp;&nbsp; 
                            <a href="https://www.salary.com/" target="_blank"
                                className="underline-this-text">salary.com</a>. 
                        </p>
                    <h8 className={this.textClass}>Tax Calculation</h8>
                        <p className={this.textClass2}>Estimated federal and FICA tax amounts derived using an online tax calculator
                          <br></br>( 
                        <a href="https://smartasset.com/taxes/colorado-tax-calculator" target="_blank"
                            className="underline-this-text">
                            tax-calculator</a>).
                        </p>
                    <h8 className={this.textClass}>Other Financial Information</h8>
                        <p className={this.textClass2}>There are many other cost items that are used 
                           within this game.
                           All items that have a financial impact (ex: miscellaneous
                           costs, health insurance, amount of college tuition, etc.) have been 
                           carefully established by doing research on the internet.   
                        </p>
                        <p>In general, costs and salaries reflect averages for the United States.
                           As a result, these amounts may not be consistent with specific 
                           geographic areas.   
                        </p>
                    </div>
            </div>
            </div>         
            
        </>
    }

}

export default InformationSources;