import LoginIcon from '../login-related/login-icon';
import LinkCard from './link_card';
import { Link } from 'react-router-dom';
import {Component} from 'react';
import ContactUs from '../about/contact_us';

class Header extends Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    
  return <>
        <span className="container m-0 p-0">
        <span className="row align-items-start w-100 header mx-0">
          <Link to="" onClick={() => this.props.onClick()}>
            <div className="float-start text-start mx-3 mt-2 primary-blue decorative col">
                <h5 className="mb-0">Moneytrain</h5>
                <h6 className="mt-0">a money management game</h6>
            </div>
          </Link>
                  
          <div className="col mr-4 d-inline-flex align-items-center mt-2">
            <h6 className="w-100 text-right mr-3 primary-blue decorative">{this.props.username}</h6>
            <LoginIcon toggleLoginMenu={()=> this.props.toggleLoginMenu()}/>
          </div>

          <Link to = "contact-us" className="contact-us-text decorative mt-3 mr-4"
                  onClick={() => this.doNothing()}>Contact Us
          </Link>

          
          <Link to = 
              {this.props.userLoggedIn == undefined || this.props.userLoggedIn === false  ?
                  ""
              :
                "special-activity-main"
              }
                onClick={this.props.userLoggedIn == undefined || this.props.userLoggedIn === false ?
                    () => this.displayDeniedWarning()
                :
                    this.doNothing()
                }>
                {this.props.username == "student4" || this.props.username == "Jan" ?  
                    <span>
                    <div className="ml-5 mt-2">
                         <button className="btn-light">
                          <h5 className="text-center primary-blue">Test Activity</h5>
                        </button>
                                             
                    </div>
                  </span>
                :
                  <div></div>
                }
            </Link>

          <Link to = 
              {this.props.userLoggedIn == undefined || this.props.userLoggedIn === false  ?
                  ""
              :
                "special-process-main"
              }
                onClick={this.props.userLoggedIn == undefined || this.props.userLoggedIn === false ?
                    () => this.displayDeniedWarning()
                :
                    this.doNothing()
                }>
                {this.props.userRole == "admin" ?  
                    <div className="mr-2 ml-2 mt-2">
                        <button className="btn-light">
                          <h5 className="text-center primary-blue">Special</h5>
                        </button>
                                  
                  </div>
                :
                  <div></div>
                }
            </Link>
            <Link to = 
              {this.props.userLoggedIn == undefined || this.props.userLoggedIn === false  ?
                  ""
              :
                "admin-main"
              }
                onClick={this.props.userLoggedIn == undefined || this.props.userLoggedIn === false ?
                    () => this.displayDeniedWarning()
                :
                    this.doNothing()
                }>
                {this.props.userRole == "admin" ?  
                    <div className="mr-3 mt-2">
                        <button className="btn-light">
                          <h5 className="text-center primary-blue">Admin</h5>
                        </button>
                  </div>
                :
                  <div></div>
                }
            </Link>
            
         
          </span> 
      </span>
    </>
  }
  
  displayDeniedWarning()
  {
      alert("Must be logged in to access.");
  }

  doNothing()
  {}

}

export default Header;