
export const setScreenSizeParameters = (screenSize, wideClass, mediumClass, narrowClass, commonClass) => {
      if (wideClass == undefined){
            wideClass = "col-sm-6";
      }
      if (narrowClass == undefined){
            narrowClass = "col-sm-8";
      }
      if (commonClass == undefined){
            commonClass = "mb-5 input-page-base-card";
      }
      let columnClass = "";
      let linkSize = "";
      let textSize = "";
      let imageSize = "";
      switch (screenSize){
            case "narrow":
                columnClass = narrowClass + " " + commonClass;
                linkSize = "verySmall";
                textSize = "small-text";
                imageSize = "very_small_img";
                break;
            case "medium":
                columnClass = mediumClass + " " + commonClass;
                linkSize = "small";
                textSize = "small-text2";
                imageSize = "small_size_img";
                break;
            default:
                columnClass = wideClass + " "  + commonClass;
                linkSize = "medium";
                imageSize = "medium_size_img";
                break; 
      }
      let returnObj={columnClass: columnClass, linkSize: linkSize, textSize: textSize, imageSize: imageSize};
   
   return returnObj;
}






