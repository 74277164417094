import {Component} from 'react';
import "./About/about.css";
import "../../css/styles.css";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import VideoViewerModal from '../for-parents/video_viewer_modal';
import Button from 'react-bootstrap/Button';

class AboutGeneral extends Component {
    constructor(props) {
        super(props);
        this.state={
            openVideoViewer: false,
        }
    }

    componentDidMount(){
        sessionStorage.removeItem("GameViewSubProcess");
    }

       render() {
            let screenParms = setScreenSizeParameters(this.props.screenSize, "col-sm-6", "col-sm-9", "col-sm-9");
            this.className = screenParms.columnClass;
            this.textClass = screenParms.textSize + " mt-2";
           
        return <>
            <div className={this.className}> 
                <div className="card mt-3 rounded-corners-lg height-max happy-retirement-img">
                    <h3 className="mt-2 p-0 text-center">About Page</h3>
                    <div className="overflow-scroll mr-3 ml-3 mb-5">
                    <h5 className="text-center">The Ultimate "Choose Your Own Adventure" Game</h5>
                  
                    <div className="mx-4">
                        <div className={this.textClass}>
                           Begin the game and enter a time portal.  When you exit the portal, you begin making the
                           choices that will determine your adventure through life.
                           You are just about to graduate high school and you have several decisions to make: What 
                           job would you like?, What car do you want to buy?,  Where do you want to live?, and What furniture 
                           will you buy?.  
                        </div>                           
                        <div className={this.textClass}>
                           Once you have finished making these decisions, you move to the next part of the game.  If the job
                           that you chose requires college, you enter the time portal again.  When you exit the portal this
                           time, you have graduated college and are ready to begin work.  This is when the game really
                           begins.  You play the game together with some virtual friends.  Your friends have distinct
                           personalities which you discover as you play.  
                        </div>                           
                        <div className={this.textClass}>
                           As things happen in the game you increase or decrease your mood score and retirement status 
                           with the choices that you make.
                           Periodically, you get to choose whether you want to do a side-hustle
                           to make more money, to do some volunteer work, just go out and have some fun, or go
                           on a vacation. 
                           These mini-excursions are fun and give you additional opportunities to improve your 
                           mood and retirement status. 
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col-md-3 text-center p-0">
                                <img className="small-size-img" src={'./assets/About/happyRetirement.png'}  alt="retirementPic"/>
                            </div>
                            <div className="col-md-9">                   
                                If you do a good job of managing your mood and retirement status, you will retire 
                                on easy street. 
                                <div className="mt-2 row">
                                    <div className="ml-2 col-sm-5"  >
                                        <Button   onClick={() => this.openVideoViewer("gameTrailer1")}>
                                            <div> View Game Trailer </div>
                                            <div> Part 1 (YouTube) </div>
                                        </Button>
                            
                                    </div>
                                    <div className="ml-2 col-sm-5">
                                        <Button   onClick={() => this.openVideoViewer("gameTrailer2")}>
                                            <div> View Game Trailer </div>
                                            <div> Part 2 (YouTube) </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            
                        {this.state.videoViewerOpen ?
                            <div className="video-display-size" >
                                <VideoViewerModal
                                    topicName={this.topicName}
                                    show={this.state.videoViewerOpen}
                                    onClose={() => this.turnVideoOff()} 
                                />
                             </div>
                            :
                                null
                        }
                        </div> 
                       
                    </div>
                   </div>                
                </div>        
            </div>

        </>
    }

    openVideoViewer(topicName){
        debugger
        this.topicName=topicName;
        this.setState({videoViewerOpen: true});
    }

    turnVideoOff(){
        this.setState({videoViewerOpen: false});
    }
    
}




export default AboutGeneral;