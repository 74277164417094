import {Component} from 'react';
import "./About/about.css";
import ContactUsModal from './contact_us_modal';
import FeedbackModal from './feedback_modal';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch'
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import SendEmail from '../../objects/CommonUse/send_email-save';
import ConfigFile from '../../datasets/config_file';
import UserSignupModal from './user_signup_modal';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state={
          openModal: false,
          loggedOn: false,
          typeOfModal: "",
          headers: setHeadersForFetch(),
          subProcess: "ContactUs",
          environment: Config.dataURL.currentEnv,
                                   
        };   
    }

    setTypeOfModal(modalName){
        this.state.typeOfModal = modalName;
        this.state.openModal = true;
    }

    closeModals() {
        this.state.typeOfModal = "";
        this.state.openModal = false;
    }
        
    submitContactUsForm(contactUsObj) {
    //    let message = "Subject: " + contactUsObj.subject + " - " + contactUsObj.messageText;
    //    need to combine subject and messageText if using emailjs...    
        let fromName = contactUsObj.firstName;
        let userName = sessionStorage.getItem("username");
        if (userName == undefined){
            userName = "NotSignedIn"
        }
        let toName = "MoneyTrain Game"
        this.emailParms={from_name: fromName, userName: userName, to_name: toName, subject: contactUsObj.subject,
            message: contactUsObj.messageText, reply_to: contactUsObj.emailAddress};
        this.state.pageStatus = "sendEmail";
        let templateName=ConfigFile.login.contactTemplate;
        let serviceID = ConfigFile.login.contactServiceID;
        let publicID = ConfigFile.login.contactPublicID;
        // SendEmail(this.emailParms, templateName, serviceID, publicID);
        this.saveContactUs();    
        var alertType = "";
        if (contactUsObj.rqstType == "UserSignon"){
             alertType = "Request for Access"
        }else{
             alertType = "Email"
        }
        alert ("Your " + alertType + " has been sent. " +
            "We will send an email reply within the next couple of days. Thank you!!" )
        this.state.typeOfModal = "";
        this.state.openModal = false;
    }
   
    
    render() {
        this.username = sessionStorage.getItem("username");
        if (this.username == null){
            this.state.loggedOn = false;
        }else{
            this.state.loggedOn = true;
        }   
        console.log("screensize is: ", this.props.screenSize);
        let screenParms = setScreenSizeParameters(this.props.screenSize, "col-sm-6", "col-sm-9", "col-sm-9");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " ml-4 mr-4 mt-4";
        this.imageClass = screenParms.imageSize;
        console.log("image class is: ", this.imageClass)
        if (this.props.screenSize == "narrow"){
              this.column1Class = "col-sm-1 mt-4 ml-3"
              this.column2Class = "col-sm-9"
        }else{
              this.column1Class = "col-sm-3 mt-4 ml-5";
              this.column2Class = "col-sm-7"
        }
                                         
    return <>
            <div className={this.className}>
                <div className="card mt-3 rounded-corners-lg height-max container"> 
                    <h3 className="mt-2 p-0 text-center">About Page</h3>
                    <h5 className="text-center">Contact Us</h5>
                    <div className="overflow-scroll container mt-2">
                        <h6 className="font-weight-bold text-center">We would love to hear from you!!</h6>
                        
                        <div className={this.textClass}>
                            You can click on the <b>"Sign Up As a Teacher or Parent"</b> button to gain access to the game
                            for a parent or teacher.  Click on the <b>"Send Email"</b> button if you have a 
                            question, or a general comment about the game.  Note:  You can get a signon as
                            an individual player by clicking on the person icon (top right corner)
                            and then clicking the "Create Individual Signon" button.  
                        </div>
                        {this.state.loggedOn ?
                            <div className={this.textClass}>
                                If you would like to provide feedback on your game experience
                                that will be used to help improve the game, please click
                                on the <b>"Provide Game Feedback'</b> button and complete the form.                           
                            </div>
                        :
                            <div className={this.textClass}>
                                If you have a signon id 
                                and would like to provide feedback on your game experience, please logon.    
                            </div>
                        }
                        <span className="row">
                            <div className={this.column1Class}>
                            <img  className={this.imageClass} src={'./assets/About/pencilPaper.png'} 
                                alt="writingPic"/>

                            </div>
                            <div className={this.column2Class}>
                             
                                {this.state.typeOfModal != "UserSignup" ?
                                    <div className="text-center mt-2 ml-4">
                                        <button className="good-looking-button" 
                                            onClick ={()=> this.setTypeOfModal("UserSignup")}>
                                               Sign Up as a Teacher or Parent
                                        </button>
                                    </div>
                           
                                :
                                    <UserSignupModal openModal={this.state.openModal} 
                                        onClose={() => this.closeModals()} 
                                        onSubmit={(contactUsObj) => this.submitContactUsForm(contactUsObj)}
                                        />
                                    }
                        
                                {this.state.typeOfModal != "SendEmail" ?
                                    <div className="text-center mt-3 ml-3">
                                        <button className="good-looking-button" 
                                            onClick ={()=> this.setTypeOfModal("SendEmail")}>
                                                Send Email
                                        </button>
                                    </div>
                           
                                :
                                    <ContactUsModal openModal={this.state.openModal} 
                                        onClose={() => this.closeModals()} 
                                        onSubmit={(contactUsObj) => this.submitContactUsForm(contactUsObj)}
                                        />
                                }
                        
                                {this.state.loggedOn ?
                                <span>
                                    {this.state.typeOfModal != "Feedback" ?
                                        <div className="text-center mt-3 ml-3">
                                            <button className="good-looking-button" 
                                                onClick ={()=> this.setTypeOfModal("Feedback")}>
                                                    Provide Game Feedback 
                                            </button>
                                        </div>
                                    :
                                        <FeedbackModal openModal={this.state.openModal} 
                                            onClose={() => this.closeModals()} 
                                            onSubmit={(feedbackForm) => this.submitFeedbackForm(feedbackForm)}
                                            />
                                    }
                                </span>
                            :
                                null
                            }
                            </div>

                        </span>
                    </div>
                </div>        
            </div>
        </>
    }

         
    submitFeedbackForm(returnVal) {
        let feedbackForm = JSON.parse(returnVal);
        const feedbackVersion = feedbackForm.feedbackVersion;
        const feedbackRole = feedbackForm.feedbackRole;
        const questionList = feedbackForm.questionList;
        let questionListOut = [];
        for (let i=0; i<questionList.length; i++){
             let questionListObj = {question: questionList[i].question,
                questionNbr: questionList[i].questionNbr, answer: questionList[i].answer};
             questionListOut.push(questionListObj);
        }
        const commentList =  feedbackForm.commentList;
        let commentListOut = [];
        for (let i=0; i<commentList.length; i++){
            let commentListObj = {question: commentList[i].question, answer: commentList[i].answer,
                questionNbr: commentList[i].questionNbr};
            commentListOut.push(commentListObj);
        }
        var emailAddress = feedbackForm.emailAddress;
        if (emailAddress == null){
            emailAddress = "not provided";
        }
        const currentDate = new Date();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        let commentDate = (cYear + "/" + cMonth + "/" + cDay);
        let feedbackObj = {
            "feedbackVersion": feedbackVersion,
            "role": feedbackRole,
            "emailAddress": emailAddress,
            "questionList": questionListOut,
            "commentList": commentListOut,
            "feedbackDate": commentDate,
        };
        const funcName = "saveFeedbackInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch(uri,
                {
                method:'post',
                headers: this.state.headers,
                body: JSON.stringify(feedbackObj)
                })
                .then(response => {
                    if (response.ok){
                        response.json().then(data => {
                            const output = JSON.parse(data);
                            const statusCode = output.statusCode;
                            if (statusCode == 200){
                                alert("Your feedback has been saved.  Thank you!!");
                            }else{
                                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                                this.props.processError(errorObj);
                            }
                    });
                };
        });
        this.state.typeOfModal = "";
        this.state.openModal = false;
    }

    saveContactUs() {
        let headers = {'Content-Type': 'application/json',
            'Authorization': {},
        };
        if (this.emailParms.userName != "NotSignedIn"){
            headers = this.state.headers;
        }
        let dateObj = getCurrentDateTime();
        let bodyObj = {
            fromName: this.emailParms.from_name,
            toName: this.emailParms.to_name,
            userName: this.emailParms.userName,
            subject: this.emailParms.subject,
            message: this.emailParms.message,
            replyTo: this.emailParms.reply_to,
            dateAdded: dateObj.date,
            timeAdded: dateObj.time
        };
        const funcName = "saveContactUsEmail";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
      
        fetch(uri,
            {
            method:'post',
            headers: headers,
            body: JSON.stringify(bodyObj)
            })
            .then(response => {
                    if (response.ok){
                        response.json().then(data => {
                            const output = JSON.parse(data);
                            const statusCode = output.statusCode;
                            if (statusCode == 200){
                                alert("Your email has been sent. We will review and " +
                                    "reply (if requested) within the next couple of " + 
                                    "days.  Thank you!!");
                            }else{
                                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                                this.props.processError(errorObj);
                            }
                    });
                };
        });
             
    }
 
}

export default ContactUs;