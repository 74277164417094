import {Component, useState} from 'react';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import editPassword from '../../objects/CommonUse/edit_password';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import SendEmail from '../../objects/CommonUse/send_email-save';
import PasswordChecklist from "react-password-checklist"
import ConfigFile from '../../datasets/config_file';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
             test: "",
             pageStatus: "initial",
             sessionStoragePfx: "userVerifyCode",
             errorTable: [],
             verifyCode: null,
             passwordErrors: false,
             updatesComplete: false,
             saveVerifyCode: false,
             userVerifyInfo: "",
             verifyCodeFound: false,
             password: "",
             confirmPassword: "",
             headers: setHeadersForFetch(),
             subProcess: "ForgotPassword",
             environment: Config.dataURL.currentEnv,
          };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    // password checklist....  refer to the register form for a complete list of possible rules

    preProcess(){
        if (this.state.errorTable.length == 0){
            this.resetErrorTable();
        }
        if (this.props.userVerifyInfo.verifyCode != undefined &&
            this.state.verifyCode != null){
            this.state.verifyCodeFound = true;
            this.state.verifyCode = this.props.userVerifyInfo.verifyCode;
            this.state.pageStatus = "verify";
        }
        if (this.state.updatesComplete == true){
            this.props.passwordResetComplete();
        }
    }

    render() { 
        this.preProcess(); 
        
    return <>
          
            {this.state.pageStatus == "initial" ?
                <span>
                {this.props.loginLoaded ? 
                  <div className="forgot-password-form px-2 pb-2"> 
                  <div>
                        <h4 className="text-center font-weight-bold">Reset User Password</h4>
                        <div>
                            <div className="decorative forgot-password-text">
                                <div>  The email process has not yet been fully setup for 
                                    this application.  So, if you click 'Proceed', this process will 
                                    send an email with a verification code to the site's email.
                                </div>
                                <div className="mt-1">
                                    The moneytrain mailbox is checked at least once each day. It may take 
                                     up to 24 hours to send the verify code.  When available, the code 
                                     will be sent to the following email address:  </div>
                                <div className="mt-2 text-center"> {this.props.emailAddress}</div>
                                <div className="mt-2">Click the "Proceed" button to continue. </div>
                            </div>
                            <div className="mx-4 mt-2 decorative forgot-password-text very-small-text">
                                If the email address noted is not correct please let us know. Click on the 
                                "Contact Us" link that is in the header page of this site.
                            </div>
                            <div className="form-group mt-4 text-right">
                                <button type="button" className="btn btn-primary" onClick={()=>this.sendEmail()}>Proceed</button>
                                <button type="button" className="ml-2 btn btn-primary" 
                                    onClick={()=>this.props.passwordResetComplete()}>Close</button>
                            </div>
                          </div>
                        </div>
                  </div>
                :
                    <div className="forgot-password-form px-2 pb-2"> 
                        <h4 className="text-center font-weight-bold">Reset User Password</h4>
                            <WaitTimeSpinner/>
                    </div>
                
                }
                </span> 
            :
                null
            }
      
            {this.state.pageStatus == "verify"  ?
                <form className="forgot-password-form px-2 pb-2" id="forgotPasswordForm" onKeyPress={this.handleKeyPress}>
                     <div className="form-group text-left">
                         <label className="font-weight-bold">Enter Validation Code</label>
                         <input type="text" className="form-control" id="verifyCodeIn" placeholder="Verify Code"/>
                     </div>
                     <div className="text-danger">{this.message}</div>
                     <div className="form-group my-2 text-right">
                         <button type="button" className="btn btn-primary" onClick={()=>this.checkVerifyCode()}>Enter</button>
                         <button type="button" className="ml-2 btn btn-primary" 
                                onClick={()=>this.resetToInitial()}>Resend</button>
                         <button type="button" className="ml-2 btn btn-primary" 
                                onClick={()=>this.props.passwordResetComplete()}>Close</button>
                     </div>
                 </form>
                 :
                     null
            }

            {this.state.pageStatus == "newPassword"  ?
                <div className="forgot-password-form px-2 pb-2" >
                    <div className="form-group text-left">
                        <label className="font-weight-bold">New Username</label>
                        <input type="text" className="form-control" id="newUsername" placeholder="Preferred username"
                            value={this.props.username}/>
                    </div>
                <form id="forgotPasswordForm">
                    <div className="form-group text-left mb-0">
                        <label className={this.state.errorTable[0].class}>New Password</label>
                        <input type="password" className="form-control" id="newPassword" placeholder="Password"
                                onChange={(val) => this.setPassword(val.target.value)}/>
                         <div className="text-danger">{this.state.errorTable[0].message}</div>
                    </div>
                    <div>
                        <label className={this.state.errorTable[1].class}>Confirm Password</label>
                        <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm password"
                            onChange={(val) => this.setConfirmPassword(val.target.value)}/>
                        <div className="text-danger">{this.state.errorTable[1].message}</div>
                    </div>
                    <PasswordChecklist
                        rules={["minLength","specialChar","match"]}
                        minLength={8}
                        value={this.state.password}
                        valueAgain={this.state.confirmPassword}
                    />
                  
                     <div className="form-group my-2 text-right">
                         <button type="button" className="btn btn-primary" onClick={()=>this.editPasswords()}>Enter</button>
                         <button type="button" className="ml-2 btn btn-primary" 
                                onClick={()=>this.props.passwordResetComplete()}>Close</button>
                     </div>
                 </form>
                 </div>
                 :
                     null
            }
            </>
    }

sendEmail(){    
    this.state.verifyCode = +calcRandomNumberWithMax(9,false,6);
  //  this.state.verifyCode = "111111";
    this.state.verifyCodeFound = true;
    this.saveUserVerify();
    let message = "Verify code is: " + this.state.verifyCode;
    this.emailParms={from_name: "MoneyTrain Game", to_name: this.props.username, userName: this.props.username, 
        message: message, reply_to: this.props.emailAddress, subject: "Password Reset"};
    this.state.pageStatus = "sendEmail";
    let templateName = ConfigFile.login.passwordTemplate;
    let serviceID = ConfigFile.login.accountServiceID;
    let publicID = ConfigFile.login.accountPublicID;
    this.saveContactUs();
  //  SendEmail(this.emailParms, templateName, serviceID, publicID);
    this.setStatusToVerify();
}

resetToInitial(){
    this.state.pageStatus = "initial";
    this.setState({true: this.state.text});
}

setStatusToVerify(){
    this.state.pageStatus = "verify";
    this.setState({true: this.state.text});
}

checkVerifyCode(){
    this.verifyCodeIn = document.getElementById("verifyCodeIn").value;
    this.message = "";
    if (this.verifyCodeIn == this.state.verifyCode){
          this.state.pageStatus = "newPassword";
    }else{
         this.message = "Verify Code Is not correct.";
    }
    this.setState({true: this.state.test});
}

setPassword(password){
     this.state.password = password;
     this.setState({password: this.state.password});
}

setConfirmPassword(password){
     this.state.confirmPassword = password;
     this.setState({password: this.state.confirmPassword});
}

editPasswords(){
    this.resetErrorTable();
    this.state.password = document.getElementById("newPassword").value;
    this.state.confirmPassword = document.getElementById("confirmPassword").value;
    this.state.errorTable = editPassword(this.state.password, this.state.confirmPassword);
    this.state.passwordErrors = false;
    for (let i=0; i<this.state.errorTable.length; i++){
        if (this.state.errorTable[i].error == true){
            this.state.passwordErrors = true;
            break;
        }
    }
    if (this.state.passwordErrors == false){
          this.updatePasswords();
    }else{
         this.setState({true: this.state.test});
    }
}

resetErrorTable(){
    this.state.errorTable = [
        {field: "password", error: false, message: "", class: "font-weight-bold"},
        {field: "confirmPassword", error: false, message: "", class: "font-weight-bold"}
    ]
}

handleKeyPress(e)
{
    if(e.key === "Enter")
    {
        this.checkVerifyCode();
    }
}   

saveUserVerify(){
    let dateObj = getCurrentDateTime();
    let verifyCode = this.state.verifyCode.toString();
    let dataObj = {
        "username": this.props.username,
        "verifyCode": verifyCode,
        "dateAdded": dateObj.date,
        "timeAdded": dateObj.time
    };
    const funcName = "saveUserVerify";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(dataObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.setState({true: this.state.saveVerifyCode})
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    })
}

updatePasswords(){
    const currDateTime = getCurrentDateTime();  
    const bodyObj = {
        "username": this.props.username,
        "password": this.state.password,
        "dateUpdated": currDateTime.date};
    const funcName = "updateLogin";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
        {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(bodyObj)})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.deleteUserVerify();
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
}
    
deleteUserVerify(){
    const funcName = "deleteUserVerify";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
        {
            method:'DELETE',
            headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.state.updatesComplete = true;
                        this.setState({true: this.state.updatesComplete});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }

  saveContactUs() {
    let dateObj = getCurrentDateTime();
    let bodyObj = {
        fromName: this.emailParms.from_name,
        toName: this.emailParms.to_name,
        userName: this.emailParms.userName,
        subject: this.emailParms.subject,
        message: this.emailParms.message,
        replyTo: this.emailParms.reply_to,
        dateAdded: dateObj.date,
        timeAdded: dateObj.time
    };
    const funcName = "saveContactUsEmail";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
        {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        alert ("Email sent to game admin - verify code sent to you in 24 hours!!");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }


}

export default ForgotPassword;