import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../components/shared-components/link_card';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import buildUrl from '../objects/CommonUse/build_URL';
import Config from '../config';

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurationAvailable: null,
            username: "",
            linkSize: "",
            screenTooSmall: false,
            largeSize: window.matchMedia("(min-width: 1007px)").matches, 
            mediumSize: window.matchMedia("(min-width: 720px").matches,
            smallSize: window.matchMedia("(min-width: 650px").matches,
            environment: Config.dataURL.currentEnv,
            subProcess: "Homepage",
        };
        this.configurationAvailable();
       
        this.setScreenSizeFormat();
    }

    setScreenSizeFormat(){
        this.className = " input-page-base-card";
        this.textClass = "mx-4 mt-4 homepage-text decorative";
        this.imgClass = "mt-5 ml-3";
        if (this.state.largeSize == true){
            this.state.linkSize = "large";
            this.screenSize = "wide";
            this.className = "col-sm-4 mb-5";
         }else{
            if (this.state.mediumSize == true){
                this.state.linkSize = "mediumLarge";
                this.className = "col-sm-4 mt-4 mb-4 ";
                this.screenSize = "medium";
                this.textClass = this.textClass + " medium-large-text";
                this.imgClass = this.imgClass + " medium_size_img";
            }else{
                this.state.linkSize = "medium";
                this.className = "col-sm-4 mt-4 mb-4 ";
                this.screenSize = "narrow";
                this.textClass = this.textClass + " medium-text";
                this.imgClass = this.imgClass + " small_size_img";
            }
            if (this.state.smallSize == false){
                this.state.linkSize = "tooSmall";
            }
        }
        console.log("screenSize is: ", this.screenSize);
        sessionStorage.setItem("GameScreenSize", this.screenSize);
    }
  
    render() {
        this.setScreenSizeFormat();
        console.log('config available?: ', this.state.configurationAvailable);
          
        return <>
        <span className="container m-0 p-0">
            <span className="row w-100 mx-0">
                <div className="col-sm-4">
                    <img className={this.imgClass} src={'./assets/sidebar_icon.png'} alt="Site Icon"/>
                    <div className={this.textClass}>
                        This is a fun game that teaches basic money management skills.  The game is
                        intended for students ages 10 and up.  Click on the "About" square to 
                        learn more.
                    </div>
                </div>
                <div className={this.className}>
                    {this.state.configurationAvailable === true ? 
                        <LinkCard name="Game" imgSrc='./assets/bg_startGame.png' destination="game-board" 
                            allowed={this.props.userLoggedIn} inSize={this.state.linkSize}
                            openLoginMenu={() => this.props.openLoginMenu()} />
                        :
                        this.state.configurationAvailable === false ?
                        <LinkCard name="Game" imgSrc='./assets/bg_startGame.png' destination="skills-interests" 
                            allowed={this.props.userLoggedIn} inSize={this.state.linkSize}
                            openLoginMenu={() => this.props.openLoginMenu()}/>

                        :
                        <LinkCard name="Game" imgSrc='./assets/bg_startGame.png' destination="" 
                            allowed={this.props.userLoggedIn} inSize={this.state.linkSize}
                            openLoginMenu={() => this.props.openLoginMenu()}/>
                    }
                    <LinkCard name="For Parents/Teachers" imgSrc='./assets/bg_forParents.png' destination="for-parents-main"
                        allowed={this.props.userLoggedIn} inSize={this.state.linkSize}
                        openLoginMenu={() => this.props.openLoginMenu()}/>
                   
                </div>
                <div className={this.className}>
                    <LinkCard name="Extra Credit" imgSrc='./assets/bg_extraCredit.png' destination="extra-credit-main" 
                        allowed={this.props.userLoggedIn} inSize={this.state.linkSize}
                        openLoginMenu={() => this.props.openLoginMenu()}/>
                    <LinkCard name="About" imgSrc='./assets/bg_about.png' destination="about-general"
                        inSize={this.state.linkSize}/>
                </div>
            </span>
        </span>
    </>
    }
    
    componentDidMount(){
        const handler = e => this.setState({largeSize: e.matches});
        window.matchMedia("(min-width: 1007px)").addEventListener('change', handler);
        const handler2 = e => this.setState({mediumSize: e.matches});
        window.matchMedia("(min-width: 720px)").addEventListener('change', handler2);
        const handler3 = e => this.setState({smallSize: e.matches});
        window.matchMedia("(min-width: 639px)").addEventListener('change', handler3);
    }

    componentDidUpdate()
    {
        if ((!this.state.configurationAvailable && this.state.configurationAvailable != false) ||
            (this.state.username != this.props.username))
        {
            this.configurationAvailable();
        }
    }

    async configurationAvailable(){
        if (this.props.userLoggedIn === false)
        {
            return;
        }
        let headers = setHeadersForFetch();
        this.state.username = sessionStorage.getItem("username");
        const funcName = "loadGame";
        const urlParm = "?mode=validateAvailable";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET',
            headers: headers
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200 || statusCode == 250){
                            if (output.body.configAvailable === true)
                                {   
                                    this.setState({configurationAvailable: true});
                                    sessionStorage.setItem("gameID", output.body.gameID);
                                } else {
                                    this.setState({configurationAvailable: false});
                                }
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                        }
                    });
                };
            });
        }

    
processErrorReturn(errorObj){
    alert ("Uh oh!  Something unexpected has occurred " +
        "error has been logged and will be addressed. " +
        "For now click OK, then return to the process " +
        "where you were and try again.");
    this.addRecordToLogfile(errorObj);
}

addRecordToLogfile(errorObj){
    console.log("errorObj is: ", errorObj);

    const headers = setHeadersForFetch();  
    const logFileBody =  {
        mainProcess: "login",
        subProcess: "login-form",
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message
    }
    let funcName = "saveLogFile"; 
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm)
    fetch (uri,  
        {method: 'POST', headers: headers, body: JSON.stringify(logFileBody)}
        )
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode = 200){
                        console.log("logfile create was successful");
                    }else{
                        alert ("Response from save log file not valid!" +
                            " status is: " + statusCode +
                            " message is: " + output.body.message)
                       
                    }
            });
            this.props.history.push('/');
        }
    });
  
}

}
export default withRouter(Homepage);