import {Component} from 'react';
//import Modal from 'react-bootstrap/Modal';
import {Button, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap"
import Draggable from 'react-draggable';
//import Button from 'react-bootstrap/Button';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import '../../css/styles.css';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class CommentModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.thumbsUpSave = this.thumbsUpSave.bind(this);
              this.thumbsSidewaysSave = this.thumbsSidewaysSave.bind(this);
              this.thumbsDownSave = this.thumbsDownSave.bind(this);
              this.state={
                thumbsUpIcon: "./assets/thumbsUp.jpg",
                thumbsSidewaysIcon: "./assets/thumbsSideways.jpg",
                thumbsDownIcon: "./assets/thumbsDown.jpg",
                thumbsChosen: "noThumbChoice",
                headers: setHeadersForFetch(),
                triggerRender: false,
                subProcess: "CommentModal",
                environment: Config.dataURL.currentEnv,
              }
            
    }

        
    render() {
  
    return <>
     <Draggable>
        <Modal isOpen={this.props.modalOpen} >
            <ModalHeader className="backgroundTopBottom"  >
                <div className="row">
                    <div className="col-sm-10">Submit a Game Comment
                    </div>
                    <div className="col-sm-1">
                    <Button outline color="primary" className="ml-5" onClick={() => this.props.onClose()} >
                        Close
                    </Button>
                    </div>
                   
                </div>
            </ModalHeader>
            <ModalBody>
                    <span>
                   
                <div>
                    {this.state.thumbsChosen == "thumbsUp" ?
                        <img src={this.state.thumbsUpIcon} 
                            className="thumbsUp-position thumbs-img" alt="thumbsUp"
                            />
                    :
                        <img src={this.state.thumbsUpIcon} 
                            className="thumbsUp-position thumbs-img buttonDisabledImage" alt="thumbsUp"
                            onClick={this.thumbsUpSave}/>
                    }
                    {this.state.thumbsChosen == "thumbsSideways" ?
                        <img src={this.state.thumbsSidewaysIcon} 
                            className="thumbsSideways-position thumbs-img" alt="thumbsSideways"
                            />
                    :
                        <img src={this.state.thumbsSidewaysIcon} 
                            className="thumbsSideways-position thumbs-img buttonDisabledImage" alt="thumbsSideways"
                            onClick={this.thumbsSidewaysSave}/>
                    }
                    {this.state.thumbsChosen == "thumbsDown" ?
                        <img src={this.state.thumbsDownIcon} 
                             className="thumbsDown-position thumbs-img" alt="thumbsDown"
                           />
                    :
                        <img src={this.state.thumbsDownIcon} 
                            className="thumbsDown-position thumbs-img buttonDisabledImage" alt="thumbsDown"
                            onClick={this.thumbsDownSave}/>
                    }
                </div>
                <div className="form-group mt-4">
                    <label className="font-weight-bold" htmlFor="comment">Comment:</label>
                    <textarea className="form-control" rows="5" id="comment"></textarea>
                </div>
                <div>
                       <b> Note:</b>  You can drag this comment box up, down, right, or left.
                </div>
            </span>
            </ModalBody>
            <ModalFooter className="p-0 backgroundTopBottom">
                <div className="mx-4 text-danger">{this.errorMessage}</div>
                <Button variant="primary"  className="mr-4" onClick={() => this.checkThumbsChosen()}>
                      Submit
                </Button>
            </ModalFooter>
            </Modal>
      </Draggable>
        </>
    }

thumbsUpSave(){
    this.setState({thumbsChosen: "thumbsUp"})
}

thumbsSidewaysSave(){
    this.state.thumbsChosen = "thumbsSideways";
}

thumbsDownSave(){
    this.setState({thumbsChosen: "thumbsDown"});
}

checkThumbsChosen(){
    if (this.state.thumbsChosen == "noThumbChoice"){
        this.errorMessage = "Click on the green thumbsUp (this is something I like about this game), " +
        "thumbsSideways (I have an idea for a change) or red thumbsDown (this is something I don't " +
        "like about this game) then click submit again.";
        this.setState({thumbsChosen: "noThumbChoice"});

    }else{
        this.errorMessage = "";
        this.submitGameComment();
    }
}

submitGameComment(){
    this.comment = document.getElementById("comment").value;
    let subProcess = sessionStorage.getItem("GameViewSubProcess");
    if (subProcess == null || subProcess == undefined){
        subProcess = "NoSubProcess";
    }
    let username = sessionStorage.getItem("username");
    if (this.comment == ""){
        this.comment = "No Comment";
    }
    let btnText = "noButtonSelected";
    let currentCardTitle = "NoCurrentCard";
    let currentCardDescription = "NoCurrentCard";
    let additionalData = {text: "noAdditionalData"};
    if (this.gameInfo.currentCard != undefined && this.props.gamePart == "GameView" &&
        this.gameInfo.currentCard.title != undefined){
        subProcess = this.gameInfo.displayType;
        currentCardTitle = this.gameInfo.currentCard.title;
        currentCardDescription = this.gameInfo.currentCard.description;
        if (this.gameInfo.btnText == "" || this.gameInfo.btnText == undefined){
            //  no button text set 
       }else{
            btnText = this.gameInfo.btnText;
       }
       if (this.gameInfo.currentCard.friendName != undefined){
            additionalData = {friendName: this.gameInfo.currentCard.friendName};
       }
    }
    if (this.props.gamePart == "Activities") { 
        if (this.gameInfo.slctActivity != undefined){
            currentCardTitle = this.gameInfo.slctActivity.activityName;
        }
        if (this.gameInfo.friendInfo.friendName != undefined){
            currentCardDescription = this.gameInfo.friendInfo.friendName;
        }
        let score = "";
        if (this.gameInfo.activityScore != undefined){
              score = this.gameInfo.activityScore;
        }
        let level = "";
        if (this.gameInfo.levelInfo != undefined){
              level = this.gameInfo.levelInfo;
        }
        additionalData = {score: score,  level: level};
    }
    let currentDateTime = getCurrentDateTime();
    this.playerFeedback = {thumbsChosen: this.state.thumbsChosen, comment: this.comment, 
        gamePart: this.props.gamePart, process: this.props.processName,  subProcess: subProcess,
        user: username, cardTitle: currentCardTitle, description: currentCardDescription, btnText: btnText, 
        additionalData: additionalData, dateAdded: currentDateTime.date, timeAdded: currentDateTime.time };
    this.savePlayerFeedback();
    this.setState({triggerRender: true});
    this.props.onClose();
}

savePlayerFeedback() {
    const funcName = "savePlayerFeedback";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
        method:'post',
        headers: this.state.headers,
        body: JSON.stringify(this.playerFeedback)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        alert("Your feedback has been saved.  Thank you!!");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
            });
        };
    });
}

}

export default CommentModal;