import ReactTooltip from "react-tooltip";
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import AboutGeneral from '../components/about/about_general';
import AboutForParents from '../components/about/about_for_parents';
import InformationSources from '../components/about/information_sources';
import GameObjectives from '../components/about/game_objectives';
import ContactUs from '../components/about/contact_us';
import CommentModal from '../components/shared-components/comment_modal';
import {Link} from "react-router-dom";
import React from 'react';
import LinkTab from "../components/shared-components/link_tab";
import { setHeadersForFetch } from "../objects/CommonUse/set_headers_for_fetch";
import {buildUrl } from '../objects/CommonUse/build_URL';

class AboutPage extends React.Component {
    constructor(props) {
        super(props);
               
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
    this.state.inputArr[inputID].selected = true;
    this.setState({inputArr: this.state.inputArr});  
        
    };

    this.openCommentModal = this.openCommentModal.bind(this);
    this.closeCommentModal = this.closeCommentModal.bind(this);

     this.state = {
        inputArr: [
            {
                completed: false,
                selected: true
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        commentIcon: "./assets/About/pencilPaper.png",
        commentModalOpen: false,
        fromProcessName: "AboutPage",
        screenSize: sessionStorage.getItem("GameScreenSize"),
        gameInfo: {},
        
    };

    switch(props.selected) {
        case "aboutMain":
            this.inputSelected(0);
            break;
        case "aboutForParents":
            this.inputSelected(1);
            break;
        case "informationSources":
            this.inputSelected(2);
            break;
        case "gameObjectives":
            this.inputSelected(3);
            break;
        case "contactUs":
            this.inputSelected(4);
            break;
                
    }
    this.getUsername();
}

getUsername(){
    this.username = sessionStorage.getItem("username");
}

    render(){
         
    return <>
            <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
                    {this.state.screenSize == "wide" ?
                        <div className="col-sm-3 mt-4 ml-3">
                            <div>
                                <img  className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                    alt="MoneyTrain Picture"/>
                            </div>   
                        </div>
                    :
                        null
                    }
               
                    {this.props.selected === "aboutGeneral" ? 
                            <AboutGeneral
                                screenSize={this.state.screenSize}/> : <span/>} 
                    {this.props.selected === "aboutForParents" ? 
                            <AboutForParents
                                screenSize={this.state.screenSize}/> : <span/>} 
                    {this.props.selected === "informationSources" ? 
                            <InformationSources
                                screenSize={this.state.screenSize}/> : <span/>}   
                    {this.props.selected === "gameObjectives" ? 
                            <GameObjectives
                                screenSize={this.state.screenSize}/> : <span/>}
                    {this.props.selected === "contactUs" ? 
                            <ContactUs
                                screenSize={this.state.screenSize}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> : <span/>}
                    {this.username == null ?
                        null
                    :
                        <div>
                            <a target="_blank" data-tip="Click to provide feedback about game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className="comment-icon-position comment-img" alt="comment"
                                    onClick={this.openCommentModal}/>
                            </a>
                        </div>
                    }

                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getGameInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }
                            
                    <div className="col-sm-2 ml-5">   
                        <Link to="about-general" onClick={() => this.handleClick(0)}>
                            <LinkTab inputArr={this.state.inputArr[0]} name="Main Page" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                                         
                        <Link to="about-for-parents" onClick={() => this.handleClick(1)}>
                             <LinkTab inputArr={this.state.inputArr[1]} name="Parent Perspective" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                        
                        <Link to="information-sources" onClick={() => this.handleClick(2)}>
                            <LinkTab inputArr={this.state.inputArr[2]} name="Information Sources" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                      
                        <Link to="game-objectives" onClick={() => this.handleClick(3)}>
                            <LinkTab inputArr={this.state.inputArr[3]} name="Game Objectives" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>
                                                             
                        <Link to="contact-us" onClick={() => this.handleClick(4)}>    
                            <LinkTab inputArr={this.state.inputArr[4]} name="Contact Us" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>                    
                    </div>    
             
            </span>
       </span>             
    </>
}

openCommentModal(){
    this.state.commentModalOpen = true;
}

closeCommentModal(){
    this.state.commentModalOpen = false;
    this.setState({commentModalopen: false});
}

handleClick = index => {
    this.state.inputSelected(index);
}


getGameInfo()
{
    return this.state.gameInfo;
}


processErrorReturn(errorObj){
       
    alert ("Uh oh!  Something unexpected has occurred " +
        "error has been logged and will be addressed. " +
        "For now click OK, then return to the process " +
        "where you were and try again.");
    this.addRecordToLogfile(errorObj);
}

addRecordToLogfile(errorObj){
    const headers=setHeadersForFetch();
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    const logFileBody =  {
        mainProcess: "About",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message
    }
    console.log("logFileBody is: ", logFileBody)
    const urlParm = "";
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
            this.props.history.push('/');
        }
    });
  
}

}
export default AboutPage;