import {Component} from 'react';
//import './Special/special.css';

class CommentsDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  


render() {
     this.commentName = "comment" + (this.props.index + 1);
          
return <>
         <div className="form-group mt-4">
                  <label className="font-weight-bold" htmlFor={this.commentName}>{this.props.commentText.question}</label>
                  <span className={this.props.commentText.textType}>
                          &nbsp;{this.props.commentText.errorMsg}</span>
                  <textarea className="form-control" rows="2" id={this.commentName}></textarea>
          </div>
           
   </>
  }
}

export default CommentsDisplayCard;